$(function () {
  $(".js-navi__toggle").on("click", function () {
    $(this).toggleClass("is-active");
    $("body").toggleClass("is-lock");
    $(".js-global-navi").toggleClass("is-active");
  });

  $(".js-accordion__toggle").on("click", function () {
    $(this).parent(".js-accordion").toggleClass("is-active");
    $(this).next().slideToggle();
  });

  $(window).on("load scroll resize", function () {
    $("html").addClass("is-loaded");
    var body = $("body");
    var offset = 400;

    if ($(this).scrollTop() > offset) {
      body.addClass("is-fixed--contents");
    } else {
      body.removeClass("is-fixed--contents");
    }
  });
});

const jsWP = document.querySelectorAll(".js-wp");

jsWP.forEach(function (jsWP) {
  const tl = gsap.timeline({
    opacity: 0,
    y: 30,
    scrollTrigger: {
      trigger: jsWP, //アニメーションが始まるトリガーとなる要素
      start: "top 90%",
      end: "bottom 0%",
      // stagger: 1,
      once: true,

      scrub: false,
      // once: true,
      // markers: true,
      toggleClass: "is-active", //クラスをつけたり、外したりできる
      // once: true,
    },
  });
});

// swiper
let SwiperMarquee = new Swiper(".js-marquee", {
  spaceBetween: 0,
  centeredSlides: true,
  speed: 12000,
  autoplay: {
    delay: 1,
  },
  loop: true,
  slidesPerView: "auto",
  allowTouchMove: false,
  disableOnInteraction: true,
});

let SwiperMarqueeBigtypo = new Swiper(".js-marquee__bigtypo", {
  spaceBetween: 0,
  centeredSlides: true,
  speed: 20000,
  autoplay: {
    delay: 1,
  },
  loop: true,
  slidesPerView: "auto",
  allowTouchMove: false,
  disableOnInteraction: true,
});

let SwiperTopPickup = new Swiper(".js-top__pickup", {
  slidesPerView: 1.2,
  spaceBetween: 24,
  // slidesPerView: "auto",
  loop: true,
  speed: 800,

  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  breakpoints: {
    769: {
      spaceBetween: 40,
      slidesPerView: "auto",
    },
  },
  navigation: {
    nextEl: ".js-top__pickup__next",
    prevEl: ".js-top__pickup__prev",
  },
});

let SwiperTopWorks = new Swiper(".js-top__works", {
  slidesPerView: 1.2,
  spaceBetween: 24,
  // slidesPerView: "auto",
  loop: true,
  speed: 800,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  breakpoints: {
    769: {
      spaceBetween: 40,
      slidesPerView: "auto",
    },
  },
  navigation: {
    nextEl: ".js-top__works__next",
    prevEl: ".js-top__works__prev",
  },
});

document.addEventListener("DOMContentLoaded", function () {
  var swiper;
  // 有効化時のオプションを記述
  function initializeSwiper() {
    swiperTopJournal = new Swiper(".js-top__journal", {
      spaceBetween: 24,
      slidesPerView: 1.1,
      // loop: true,

      // pagination: {
      //   el: ".swiper-pagination",
      //   clickable: true,
      // },
      navigation: {
        nextEl: ".js-top__journal__next",
        prevEl: ".js-top__journal__prev",
      },
    });
  }
  // 無効化する用の記述
  function destroySwiper() {
    if (swiper) {
      swiper.destroy();
      swiper = undefined;
    }
  }
  // if分を用いて横幅に応じて関数を実行
  function handleResize() {
    var w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (w < 500) {
      if (!swiper) {
        initializeSwiper();
      }
    } else {
      destroySwiper();
    }
  }
  // 初回実行
  handleResize();
  window.addEventListener("resize", handleResize);
});

$(function () {
  //SERVICE 文章の開閉
  $(".js-btn-more").click(function () {
    $(".js-btn-more__target").addClass("is-show");
    $(this).fadeOut(300);
  });
  //JOURNAL等のmoreボタン
  var post_add = 6;
  var post_count = post_add - 1;
  var post_more_btn = $(".js-post-more");
  var post_more_target = $(".js-post-more__target>li");
  var post_num = post_more_target.length;
  post_more_btn.hide();
  post_more_target.slice(0, post_add).show();
  if (post_num > post_add) {
    post_more_btn.show();

    post_more_btn.click(function () {
      for (var i = 0; i < post_add; i++) {
        post_more_target.eq(post_count + 1).fadeIn(300);
        post_count++;
        if (post_count + 1 >= post_num) {
          post_more_btn.fadeOut(300);
          break;
        }
      }
    });
  }
  //活動事例のmoreボタン
  var works_add = 4;
  var works_count = works_add - 1;
  var works_more_btn = $(".js-works-more");
  var works_more_target = $(".js-works-more__target>li");
  var works_num = works_more_target.length;
  works_more_btn.hide();
  works_more_target.slice(0, works_add).show();
  if (works_num > works_add) {
    works_more_btn.show();

    works_more_btn.click(function () {
      for (var i = 0; i < works_add; i++) {
        works_more_target.eq(works_count + 1).fadeIn(300);
        works_count++;
        if (works_count + 1 >= works_num) {
          works_more_btn.fadeOut(300);
          break;
        }
      }
    });
  }
  //リンク内リンク
  $(".js-link-priority").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    var link_url = $(this).attr("data-url");
    location.href = link_url;
  });
  $(".js-link-copy").click(function () {
    const element = document.createElement("input");
    element.value = location.href;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
    alert("URLをコピーしました");
  });
});
